<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row>
      <v-col>

        <v-card flat class="card_action">

            <v-row align-items="center">
              <v-col class="flex_end">
                <v-btn
                  color="error"
                  depressed
                  tile
                  @click="delete_sprint()"
                  v-if="$store.state.user_permissions.project_sprint_delete"
                >
                  Usuń
                </v-btn>
                <v-btn
                  color="primary"
                  depressed
                  tile
                  @click="update_sprint()"
                >
                  Zapisz zmiany
                </v-btn>
              </v-col>
            </v-row>

        </v-card>
        
        <v-form v-model="valid">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col>


                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="user_data.name"
                        label="Nazwa etapu"
                        color="primary"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="user_data.hours"
                        label="Przewidywany czas na zadanie (godziny)"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        v-model="selected_users"
                        :items="users"
                        item-text="name"
                        item-value="id"
                        class="classic_select"
                        chips
                        label="Przypisani użytkownicy"
                        multiple
                      ></v-select>
                    </v-col>
                  </v-row>

                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>

      </v-col>
    </v-row>
    
  </div>
</template>

<script>
// #TODO zwraca nieprawidlowa wartosc w userach i nie da sie  ich prawidlowo podpiac (zimny musi zwracac zamiast assign_id samo id (inaczej trzeba uzyc petli))
export default {
  data: () => ({
    valid: false,
    id: 0,
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista projektów',
        disabled: false,
        to: '/projekty',
      },
      {
        text: 'Projekt',
        disabled: false,
        to: '/projekt',
      },
      {
        text: 'Etap',
        disabled: false,
        to: '/sprint',
      },
      {
        text: 'Edytuj etap',
        disabled: true,
        to: '/projekt-nowy',
      },
    ],

    user_data: {
      project_name: '',
      hours: 0,
    },
    selected_users: [],
    users: []
  }),
  methods: {
    update_sprint() {
      this.$store.commit('loader');

      let formData = new FormData();
      formData.append("name", this.user_data.name);
      if(this.user_data.hours) formData.append("hours", this.user_data.hours);
      
      if(this.selected_users.length){
        for(let index in this.selected_users){
          formData.append("assign_id["+index+"]", this.selected_users[index]);
        }
      }

      this.$axios({url: this.$store.state.api +'/sprint/'+this.id+'?_method=PUT', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
        .then(resp => {
          this.$router.push('/sprint/'+this.id);
          this.$store.commit("snackbar", {
            text: "Zaktualizowano sprint",
            color: "primary",
            btn_color: "white"
          });
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then (() => {
          this.$store.commit('loader_off');
        })
    },
    // #TODO edycja nie dziala usuwanie dziala, nalezy tylko przejsc do walsciwego projektu
    delete_sprint(){
      this.$store.commit('loader');

      this.$axios({url: this.$store.state.api +'/sprint/'+this.id, data: {}, method: 'DELETE', headers: {'Content-Type': 'multipart/form-data'} })
        .then(resp => {
          this.$router.push('/projekt/'+this.user_data.project_id);
          this.$store.commit("snackbar", {
            text: "Usunięto etap",
            color: "primary",
            btn_color: "white"
          });
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then (() => {
          this.$store.commit('loader_off');
        })
    }
  },
  mounted(){
    this.id = this.$route.params.id;
    this.breadcrumb[3].to = '/sprint/'+this.id;

    this.$store.commit('loader');
    this.$axios({url: this.$store.state.api +'/sprint_task/'+this.id, data: {}, method: 'GET' })
      .then(resp => {
        this.user_data = resp.data.sprint;
        for(let z=0; z<this.user_data.users.length; z++){
          this.selected_users.push(this.user_data.users[z].id)
        }
        this.breadcrumb[2].to = '/projekt/'+this.user_data.project_id;
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        this.$store.commit('loader_off');
      })

    this.$axios({url: this.$store.state.api +'/auth/user', data: {}, method: 'GET' })
      .then(resp => {
        this.users = resp.data;
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
</style>